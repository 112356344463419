body {
    background-color: #111;
    font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
*{
	margin: 0;
	padding: 0;
}
*, ::before, ::after {
    box-sizing: border-box;
    border-width: 0;
    border-style: solid;
    border-color: #e5e7eb;
}
ol, ul, menu {
    list-style: none;
    margin: 0;
    padding: 0;
}

[type='text'],
[type='email'],
[type='url'],
[type='password'],
[type='number'],
[type='date'],
[type='datetime-local'],
[type='month'],
[type='search'],
[type='tel'],
[type='time'],
[type='week'],
[multiple],
textarea,
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: #fff;
    border-color: #6b7280;
    border-width: 1px;
    border-radius: 0px;
    padding-top: 0.5rem;
    padding-right: 0.75rem;
    padding-bottom: 0.5rem;
    padding-left: 0.75rem;
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-shadow: 0 0 #0000;
}


button {
    background-image: none;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 0.25rem;
    text-align: center;
    border: none;

    &:hover {
        opacity: 0.8;
    }
}

select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
    background-position: right 0.5rem center;
    background-repeat: no-repeat;
    background-size: 1.5em 1.5em;
    padding-right: 2.5rem;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
}
img, video {
    max-width: 100%;
    height: auto;
}

@import url(./Toast/index.less);
@import url(./other/footer.less);
@import url(./other/loading.less);
@import url(./other/popup.less);
//QRP page
@import url(./style9/index.less);
@import url(./style10/index.less);
@import url(./onlyForm/index.less);
@import url(./w88/index.less);
@import url(./restrict/index.less);

@hack: true;@import '/buildDir/data/Less/antd-custom.less';