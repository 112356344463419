//uniqlo-id
.style9 {
    position: relative;
    overflow: hidden;

    //banner
    .ant-carousel {
        min-height: 820px;

        .slick-list {
            .slick-track {
                display: flex;
            }

            .img_wrap {
                background-color: #111;
                height: auto;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    min-height: 820px;
                    object-position: center top;
                }
            }
        }
    }

    //header
    .header {
        background-color: #111;
        &-wrapper {
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            overflow: hidden;
            padding: 22px 16px;
        }

        .logo-wrapper {
            flex: none;
            width: 220px;
        }

        &-form {
            display: grid;
            grid-auto-flow: column;
            gap: 0.5rem;

            .box {
                display: flex;
                flex-direction: column;

                input {
                    text-overflow: ellipsis;
                    padding: 0.25rem;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    border: 1px solid #ccc;
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                }

                &>*+* {
                    margin-top: 0.25rem;
                }
            }

            &-wrapper {
                &>*+* {
                    margin-top: 0.5rem;
                }
            }

            .web-input {
                margin-top: 0.125rem;

                &:focus {
                    border: 1px solid @betway-green;
                }
            }

            .error-code {
                // display: none;
                font-size: 13px;
                color: @betway-error;
            }
        }

        .login {
            display: inline-block;
            border-radius: 0.25rem;
            font-size: 0.875rem;
            line-height: 1.25rem;
            padding: 0.375rem 0.75rem;
            background-color: @betway-green;
            color: #fff;

            &.mobile {
                display: none;
            }
        }

        &-action {
            display: flex;
            justify-content: flex-end;

            &>*+* {
                margin-left: 0.75rem;
            }

            .text-link {
                cursor: pointer;
                font-size: 0.875rem;
                line-height: 1.25rem;
                color: @betway-green;

                &:hover {
                    opacity: 0.8;
                }

                svg {
                    display: inline-block;
                    height: 1.25rem;
                    width: 1.25rem;
                    vertical-align: middle;
                    margin-right: 0.25rem;
                }
            }
        }
    }


    // form
    .central {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        top: 19.5rem;
        z-index: 10;
        background-color: #111;
        width: 600px;

        .title {
            border-top-width: 4px;
            border-color: @betway-green;
            border-top-style: solid;
            background-color: rgba(38, 38, 38, 1);
            padding: 1rem 0;
            text-align: center;

            h3 {
                color: @betway-green;
                font-size: 1.875rem;
                line-height: 2.25rem;
                margin: 0;
            }
        }

        .form-wrapper {
            padding: 1rem 1.25rem 2.5rem 1.25rem;
            color: #fff;

            &>*+* {
                margin-top: 1rem;
            }

            .desc {
                text-align: center;
                letter-spacing: 0.05em;
                font-size: 0.875rem;
                line-height: 1.25rem;
            }

            .form-block {
                &>*+* {
                    margin-top: 1rem;
                }

                .login-item {
                    display: grid;
                    row-gap: 0.5rem;
                    -moz-column-gap: 0.25rem;
                    column-gap: 0.25rem;

                    &.grid-cols-4 {
                        grid-template-columns: repeat(4, minmax(0, 1fr));
                    }

                    .form-label {
                        display: grid;
                        align-content: center;
                        font-size: 1rem;
                        // line-height: 1.5rem;
                        line-height: 18px;
                    }

                    .input-control {
                        color: #FFF;
                        outline: 2px solid transparent;
                        outline-offset: 2px;
                        margin: 0 0.125rem;
                        border-width: 1px;
                        border-color: rgb(38, 38, 38, 1);
                        background-color: rgb(38, 38, 38, 1);
                        font-size: 0.875rem;
                        line-height: 1.25rem;

                        &.disabled {
                            cursor: not-allowed;
                            text-align: center;
                        }

                        &.birthday {
                            padding-right: 1.75rem;
                            color: #908585;
                            background-color: rgba(255, 255, 255, 1);
                        }

                        &:focus {
                            border: 1px solid @betway-green;
                        }
                        &#inputState{
                            color: #282828;
                            background-color: #FFF;
                        }
                    }

                    .error-txt {
                        margin-left: 0.5rem;
                        margin-right: 0.5rem;
                        word-break: break-all;
                        font-size: 0.75rem;
                        line-height: 1rem;
                        color: @betway-error;
                    }

                    .col-span-2 {
                        grid-column: span 2 / span 2;
                    }

                    .col-span-3 {
                        grid-column: span 3 / span 3;
                    }

                    .col-start-2 {
                        grid-column-start: 2;
                    }

                    .col-start-3 {
                        grid-column-start: 3;
                    }
                }
            }
        }

        .submit {
            margin-left: auto;
            margin-right: auto;
            display: block;
            cursor: pointer;
            white-space: nowrap;
            border-radius: 0.25rem;
            border-style: none;
            --tw-bg-opacity: 1;
            background-color: @betway-green;
            padding-top: 0.5rem;
            padding-bottom: 0.5rem;
            padding-left: 2.5rem;
            padding-right: 2.5rem;
            text-align: center;
            font-size: 1.25rem;
            line-height: 1.75rem;
            font-weight: 900;
            --tw-text-opacity: 1;
            color: #fff;
        }
    }
}

.style9.pink {
    //header
    .header {
        &-form {
            .web-input {
                &:focus {
                    border: 1px solid @betway-pink;
                }
            }
        }

        .login {
            background-color: @betway-pink;
        }

        &-action {
            .text-link {
                color: @betway-pink;
            }
        }
    }


    // form
    .central {
        .title {
            border-color: @betway-pink;
            h3 {
                color: @betway-pink;
            }
        }
        .form-wrapper {
            .form-block {
                .login-item {
                    .input-control {
                        &:focus {
                            border: 1px solid @betway-pink
                        }
                    }
                }
            }
        }
        .submit {
            background-color: @betway-pink;
        }
    }
}

.style9.pink.m1-001 .central .title h3 {
            color: #fff;
}

@import url('./mobile.less');