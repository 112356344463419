//uniqlo-id
.w88 {
    position: relative;
    overflow: hidden;

    //banner
    .ant-carousel {
        height: 500px;

        .slick-list {
            .slick-track {
                display: flex;
            }

            .img_wrap {
                height: auto;

                img {
                    object-fit: cover;
                    height: 100%;
                    width: 100%;
                    height: 500px;
                    object-position: center top;
                }
            }
        }
    }

    //header
    .header {
        background-color: #111;
        text-align: center;
        margin: 60px auto 30px;
        display: inline-flex;
        width: 100%;
        align-items: center;

        img {
            max-height: 90px;
        }
        .logo{
            &Left {
                margin-left: auto;
                margin-right: 1rem;
            }
            &Right {
                margin-left: 0;
                margin-right: auto;
                border-left: 1px solid #8c8c8c;
                padding-left: 1rem;
            }
        }
    }

    // center
    .central {
        width: 100%;
        text-align: center;
        color: #FFF;
        padding: 20px 0;
        font-size: 18px;

        &>*+* {
            margin-top: 1.25rem;
        }

        .w88-color {
            color: @w88-yellow;
        }
        .betway-color {
            color: @betway-green;
        }
        .contactButton {
            display: inline-flex;
            text-align: center;
            justify-content: center;
            align-items: center;

            &>*+* {
                margin-left: 1rem;
            }
            a {
                display: block;
                font-size: 18px;
                padding: 0.75rem 1.5rem;
                border-radius: 2px;
                border: none;
                color: #FFF;
                text-decoration: none;

                &.cs {
                    background-color: #444;
                }
                &.betway {
                    background-color: @betway-green;
                }
            }
        }

        .copyright {
            font-size: 16px;
        }
    }

}

@import url('./mobile.less');