.overlay {
    background-color: rgba(0, 0, 0, 0.6);
    justify-content: center;
    align-items: center;
    height: 100vh;
    display: flex;
    z-index: 90;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;

    .popup {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0;

        &.qrcodeBox {
            height: auto;
            width: 20rem;
            border: 1px solid rgb(229 229 229);

            .contentBlock {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                padding: 1.25rem;
                background-color: rgb(38 38 38);

                &>*+* {
                    margin-top: 1rem;
                }

                .qrcodeImg {
                    max-width: 248px;
                }

                .qrcode {
                    &-text {
                        font-size: 1rem;
                        line-height: 1.5rem;
                        text-align: center;
                        color: #FFF;
                    }
                }
            }
        }

        &.msgBox {
            width: 24rem;

            .titleBar {
                border-top: 8px;
                border-style: solid;
                border-color: @betway-green;
                padding: 0.75rem 1.25rem;
                font-weight: bold;
                display: flex;
                justify-content: space-between;
                background-color: rgb(23 23 23);

                h3 {
                    font-size: 1.25rem;
                    line-height: 1.75rem;
                    color: #FFF;
                }
            }

            .contentBlock {
                &>*+* {
                    margin-top: 1rem;
                }

                padding: 1.25rem;
                background-color: rgb(38 38 38);
                color: #FFF;
                font-size: 1rem;
                line-height: 1.5rem;
                text-align: center;
            }

            .closeBtn {
                &Msg {
                    color: #FFF;
                    opacity: 0.3;
                    background-color: transparent;
                    border-radius: 0;
                    font-size: 100%;
                    line-height: 0;

                    &:hover {
                        opacity: 0.6;
                    }

                    svg {
                        width: 18px;
                        height: 18px;
                    }
                }

                &Bottom {
                    color: #FFF;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    padding: 0.5rem;
                    background-color: @betway-green;
                    border: 1px solid #FFF;
                    width: 100%;
                    text-align: center;
                    border-radius: 0;
                }
            }
        }

        .closeBtn {
            color: #FFF;
            padding: 0.5rem;
            background-color: #000;
            border: 2px solid rgb(229 229 229);
            border-radius: 9999px;
            font-size: 100%;
            line-height: 0;
            cursor: pointer;

            &Block {
                position: absolute;
                right: -0.75rem;
                top: -0.75rem;
            }
        }
    }

    @media (max-width:550px) {
        .popup {
            width: 100%;
            margin: 2rem;
        }
    }
}

.pink+.overlay {
    .popup {
        &.msgBox {
            .titleBar {
                border-color: @betway-pink;
            }

            .closeBtn {
                &Bottom {
                    background-color: @betway-pink;
                }
            }
        }
    }
}

.purple+.overlay {
    .popup {
        &.msgBox {
            .titleBar {
                border-color: @betway-purple;
            }

            .closeBtn {
                &Bottom {
                    background-color: @betway-purple;
                }
            }
        }
    }
}