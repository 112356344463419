// 响应式布局
.w88{
	min-height: 100vh;
	// 手机模式
	@media screen and (max-width:1023px){
		
	}
	@media screen and (max-width:767px) {
		//banner
		.ant-carousel {
			height: 275px;
	
			.slick-list {
				.img_wrap {
					img {
						height: 275px;
					}
				}
			}
		}
		//header
		.header {
			margin: 40px auto 20px;
			img {
				max-height: 50px;
			}
		}

		    // center
			.central {
				font-size: 12px;
				&>*+* {
					margin-top: 1rem;
				}
	
				.contactButton {
					a {
						font-size: 14px;
					}
				}
		
				.copyright {
					font-size: 12px;
				}
			}
	}
	@media screen and (max-width:500px) {
	}
}