@keyframes spin {
    to {
        transform: rotate(1turn)
    }
}
.loadingWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 50;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.6);
}

svg.loading {
    height: 2.5rem;
    width: 2.5rem;
    color: #fff;
    animation: spin 1s linear infinite;
}
.light-circle {
    opacity: 0.25;
}

.path {
    fill: currentColor;
    opacity: 0.75;
}

