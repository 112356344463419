//uniqlo-id
.onlyForm {
    position: relative;
    overflow: hidden;
    width: 400px;
    margin: 0 auto;
    height: 100vh;

    // form
    .form-wrapper {
        padding: 1.5rem 1.75rem;
        color: #fff;
        background-color: #111;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        height: 100vh;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        .form-block {
            &>*+* {
                margin-top: 1rem;
            }

            .login-item {
                display: grid;
                row-gap: 0.5rem;
                -moz-column-gap: 0.25rem;
                column-gap: 0.25rem;

                &.grid-cols-4 {
                    grid-template-columns: repeat(4, minmax(0, 1fr));
                }

                .form-label {
                    display: grid;
                    align-content: center;
                    font-size: 1rem;
                    // line-height: 1.5rem;
                    line-height: 18px;
                }

                .input-control {
                    color: #FFF;
                    outline: 2px solid transparent;
                    outline-offset: 2px;
                    margin: 0 0.125rem;
                    border-width: 1px;
                    border-color: rgb(38, 38, 38, 1);
                    background-color: rgb(38, 38, 38, 1);
                    font-size: 0.875rem;
                    line-height: 1.25rem;

                    &.disabled {
                        cursor: not-allowed;
                        text-align: center;
                    }

                    &.birthday {
                        padding-right: 1.75rem;
                    }

                    &:focus {
                        border: 1px solid @betway-green;
                    }
                }

                .error-txt {
                    margin-left: 0.5rem;
                    margin-right: 0.5rem;
                    word-break: break-all;
                    font-size: 0.75rem;
                    line-height: 1rem;
                    color: @betway-error;
                }

                .col-span-2 {
                    grid-column: span 2 / span 2;
                }

                .col-span-3 {
                    grid-column: span 3 / span 3;
                }

                .col-start-2 {
                    grid-column-start: 2;
                }

                .col-start-3 {
                    grid-column-start: 3;
                }
            }

            .login-hint {
                .text-link {
                    cursor: pointer;
                    font-size: 0.875rem;
                    line-height: 1.25rem;
                    color: @betway-green;
    
                    &:hover {
                        opacity: 0.8;
                    }
    
                    svg {
                        display: inline-block;
                        height: 1.25rem;
                        width: 1.25rem;
                        vertical-align: middle;
                        margin-right: 0.25rem;
                    }
                }
            }
        }
    }

    .submit {
        margin: 0 auto;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        border-radius: 0.25rem;
        border-style: none;
        background-color: @betway-green;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 2.5rem;
        padding-right: 2.5rem;
        text-align: center;
        font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 900;
        color: #fff;
        &.login {
            width: 100%;
            font-size: 1rem;

        }
    }
}

@import url('./../other/footer.less');
@import url('./mobile.less');