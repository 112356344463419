// 响应式布局
.style9{
	// 手机模式
	@media (max-width:1023px){
		.ant-carousel {
			min-height: auto;

			.slick-list {
				.img_wrap {
					img {
						min-height: auto;
					}
				}
			}
		}

		.header {
			&-wrapper {
				align-items: center;
			}
			.logo-wrapper {
				max-width: 180px;
				.qrp-header-logo {
					width: 100%;
				}
			}
			&-form {
				display: none;
				&-wrapper {
					&>*+* {
						margin-top: 0;
					}
					.login.mobile {
						display: block;
						text-decoration: none;
					}
				}
			}
			&-action {
				display: none;
			}


		}

		// form
		.central {
			position: static;
			transform: none;
			width: 100%;

			.title {
				h3 {
					color: #fff;
				}
			}

			.form-block {
				.input-control {
					&.birthday {
						// grid-column: span 3 / span 3;
						// grid-column-start: 2;
					}
				}
			}
		}

		.footer {
			display: none;
		}
	}
	@media screen and (max-width:767px) {

	}
	@media screen and (max-width:500px) {

	}
}
