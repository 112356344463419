.restrict-page{
    .restrict-head{
        padding: .5rem 2.5rem;
    }
    .restrict-bg{
        background: url(/static/images/restrict/bg-ipregisterrestricted.jpg) no-repeat center;
        overflow: hidden;
        background-size: cover;
        min-height: 50rem;
        max-width: 100%;
        .textContent{
            padding: 2rem;  
            max-width : 80rem;
            display: flex;
            margin: 20px auto 0;
            justify-content: center;
            color: #FFF;
            flex-direction: column;
            align-items: center;
            h2{
                font-size: 3.75rem;
                line-height: 1;
                margin-bottom: 20px;
            }
            .restrict-flex{

                display: flex;
                justify-content: space-between;
                margin-top: 24px;
                div.item{
                    width: 32%;
                    text-align: center;
                    p{
                        line-height: 2.25rem;
                        font-size: 1.25rem;
                        font-weight:400;
                    }
                    a.btn{
                        display: flex;
                        color: #FFF;
                        outline: 2px solid #0000;
                        outline-offset: 2px;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        background-color: rgb(0 179 36);
                        border-color: rgb(0 179 36);
                        border-width: 1px;
                        border-radius: 0.25rem;
                        cursor: pointer;
                        padding-top: 0.75rem;
                        padding-bottom: 0.75rem;
                        justify-content: center;
                        &:hover{
                            color: rgb(0 179 36);
                            background-color: #FFF;
                        }
                    }
                    a+a{
                        --tw-space-y-reverse: 0;
                        margin-top: calc(1.5rem*(1 - var(--tw-space-y-reverse)));
                        margin-bottom: calc(1.5rem*var(--tw-space-y-reverse));
                    }
                }
            }
        }
    }


    @media screen and (max-width:1023px){
        .restrict-head{
            img{
                width: 100px;
            }
            padding: .5rem 2.5rem;
        }
        .restrict-bg{
            .textContent{
                display: flex;
                flex-direction: column;
                margin-top: 20px;
                h2{
                    font-size: 2rem;
                    line-height: 1;  
                    text-align: center; 
                }
                .restrict-flex{
                    flex-direction: column;
                    align-items: center;
                    div.item{
                        width: 95%;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-bottom: 40px;
                        a.btn{
                            width: 80%;
                        }
                    }
                   
                }
            }
        }
    }
}